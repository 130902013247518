.alert-panel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  overflow: auto;
  background: $success;
  border: 0;
  border-bottom: 1px solid #fff;

  &.alert-panel--is-error {
    background: $warning;
  }

  .alert-panel__close {
    position: absolute;
    top: 30px;
    right: 20px;
    width: 15px;
    min-width: 15px;
    height: 15px;
    padding: 0;
    color: #fff;
    background: none;

    img {
      width: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  span {
    padding: 40px 35px;
    color: #fff;
    font-weight: 600;
    text-align: center;
  }
}
