.form {
  width: 100%;

  .Mui-focused {
    input {
      color: $form-text-color;
    }
  }
}

.form__form-inner {
  width: 100%;
  max-width: $form-width;
  margin: 0 auto;

  &-text {
    margin: 20px 0;
    color: #d8d8d8;
    font: normal normal normal 16px/22px $primary-font;
    text-align: center;

    &-yellow {
      margin-top: 10px;
      color: #fcbc00;
      font: italic normal normal 12px/17px $primary-font;
      text-align: center;
    }
  }

  h1,
  h2 {
    color: $accent-primary;
    font-weight: 600;
    font-size: 1.6rem;
    font-family: $title-font;
    text-align: left;

    @media screen and (max-width: $screens-sm) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }

  label {
    @media screen and (max-width: $screens-sm) {
      line-height: 1.7rem;
    }
  }

  p {
    display: block;
    line-height: 1.5rem;
    text-align: left;
  }

  a {
    margin: 10px 0;
    text-decoration: none;
  }

  input[type='checkbox'] {
    border-color: $accent-primary;
  }

  svg {
    color: $accent-primary;
  }

  input::placeholder {
    color: $form-text-color;
  }

  button.MuiButtonBase-root:last-child,
  button:last-child {
    width: 100%;
    max-width: 100%;
  }
}

.form__textfield-with-buttons {
  .form__textfield-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    button {
      max-width: 130px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}

@media (max-width: $screens-sm) {
  form.form {
    margin: 30px auto;
    padding: 0 30px;

    .form__form-inner {
      h2 {
        font-size: 1.4rem;
      }

      button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
        min-width: 100%;
      }
    }
  }
}
