.validation {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  line-height: 1.1;

  & + & {
    margin-top: 5px;
  }

  &::before {
    width: 20px;
    height: 20px;
    margin-right: 2px;
  }

  &.--valid::before {
    color: #00b800;
    content: '\2713';
  }

  &.--invalid::before {
    content: '\292B';
  }
}
