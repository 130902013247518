.react-tel-input {
  &.phone-numbers-old {
    .form-control {
      padding-left: 70px;
      height: 56px;
      width: 100%;
      background-color: transparent;
      border-color: white;
      color: white;
      font-size: 16px;

      + .flag-dropdown {
        border-color: white;
      }

      &:hover,
      &:focus {
        border-color: $accent-primary;

        + .flag-dropdown {
          border-color: $accent-primary;
        }
      }

      &:focus,
      &.open {
        outline: 2px solid $accent-primary;
        border-color: transparent;

        + .flag-dropdown {
          outline: 2px solid $accent-primary;
          border-color: transparent;
        }
      }
    }
  }

  &.phone-numbers {
    .form-control {
      background-color: transparent;
      border-radius: $radius-base;
      border-color: $border-color;
      border-width: $control-border-width;
      font-family: inherit;
      font-size: 20px;
      height: $control-height;
      padding-left: 75px;
      width: 100%;

      @media (max-width: $screens-sm) {
        height: $control-height-mobile;
        font-size: 16px;
      }

      + .flag-dropdown {
        border-color: $border-color;
        border-top-left-radius: $radius-base;
        border-bottom-left-radius: $radius-base;
      }

      &:focus,
      &.open {
        border-color: $accent-primary;

        + .flag-dropdown {
          border-color: $accent-primary;
        }
      }
    }

    .country-list {
      background-color: $control-bg;
      border: $control-border-width solid $border-color;

      .country {
        border-radius: $radius-base;
        color: $control-color;
        font-size: 16px;
        padding: 5px 10px;
        margin-left: 10px;
        margin-right: 10px;

        &:hover,
        &:focus,
        &.highlight {
          background-color: $control-hover-bg;
        }

        .dial-code {
          color: $control-color;
        }
      }

      > * + * {
        margin-top: 12px;
      }

      .divider {
        border-color: $border-color;
      }
    }

    &_readonly {
      .form-control {
        border-color: transparent !important;
        padding-left: 20px !important;
      }

      .flag-dropdown {
        display: none;
      }
    }
  }

  .flag-dropdown {
    background: transparent !important;

    .selected-flag {
      background: transparent !important;
      width: 60px;

      .arrow {
        border-top-color: white;
        left: 38px;

        &.up {
          border-bottom-color: white;
        }
      }
    }
  }
}
