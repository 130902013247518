// tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply text-buttonColor rounded-base text-[16px] font-semibold min-h-mobileCotrol sm:min-h-control min-w-[220px] px-4 py-2 transition-all;
  }

  .type-primary {
    @apply font-primary font-normal;
  }

  .type-title {
    @apply font-title font-semibold;
  }

  .type-numberPlate {
    @apply font-number;
  }
}

// vendor
@import 'normalize';
@import 'variables';
@import 'animations';
@import 'base';
@import 'elements';
@import 'utilities';

//components
@import 'shared/material-ui-form';
@import 'shared/material-ui-table';
@import 'shared/material-ui-progress-bar';
@import 'shared/mui-switch';
@import 'shared/form';
@import 'shared/button';
@import 'shared/edit-dropdown';
@import 'shared/alert-panel';
@import 'shared/country-phone-input';
@import 'shared/form-error-message';
@import 'shared/hamburger';
@import 'shared/loading-circle';
@import 'shared/mobile-nav';
@import 'shared/password-requirements';
@import 'shared/view-container';
@import 'shared/logout-notification';
@import 'shared/version-notifier';
@import 'shared/daypicker';
@import 'shared/mui-forms';
@import 'shared/phone-numbers';

// views
@import 'views/landing';
@import 'views/error';
@import 'views/help';
@import 'views/chart';
