.help-view {
  width: 30%;
  margin-top: 60px !important;
  @media (max-width: 1200px) {
    width: 60%;
  }
  @media (max-width: 800px) {
    width: 100%;
    padding: 20px;
  }

  &__image-container {
    display: flex;
    justify-content: center;
    width: 100%;

    svg {
      width: 75px;
      height: 75px;
    }
  }

  &__phone-text {
    width: 100%;
    color: $accent-primary;
    font-size: 14px;
    text-align: center;

    a {
      display: inline-block;
      text-decoration: underline;
    }
  }

  .--italic {
    font-style: italic;
  }

  &__form-inner {
    h4 {
      display: flex;
      justify-content: flex-start;
      color: #fff;
      font-weight: 600;
      font-size: 30px;
    }

    h2 {
      display: flex;
      justify-content: flex-start;
      color: #fff;
      font-weight: 600;
      font-size: 30px;

      svg {
        display: inline-block;
        width: 35px;
        height: 35px;
      }
    }

    .--yellow {
      color: $accent-primary;
    }

    button {
      margin: 30px 0;
    }
  }
}

.help-view__finished {
  margin: 100px auto 30px;
  padding: 0 30px;

  .MuiTypography-gutterBottom {
    margin-bottom: 21px;

    &:first-child {
      margin-bottom: 30px;
      font-weight: 700;
      font-size: 1.7rem;
    }
  }

  h6 {
    font: normal normal normal 14px/19px $primary-font;
  }
}
